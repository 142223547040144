import React from "react"
import Image from "../Image"
import Carousel from "react-multi-carousel"
import "../../styles/sections/bank-section.css"
import "react-multi-carousel/lib/styles.css"

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 5,
    slidesToSlide: 5
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3
  },
  inBetween: {
    breakpoint: { max: 768, min: 464 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2
    // slidesToSlide: 1, // optional, default to 1.
  }
}

const bankLogos = [
  "50-WEALTHONEBANK.png",
  "50-b2b.png",
  "50-blueshore.png",
  "50-bridgewater.jpg",
  "50-costcapital.png",
  "50-envision.png",
  "50-FIRSTNATIONAL.png",
  "50-homeTrust.png",
  "50-icici.png",
  "50-LENDWISE.png",
  "50-manulife.png",
  "50-mcap.png",
  "50-MERIX.png",
  "50-OPTIMUM.png",
  "50-PEOPLESTRUST.png",
  "50-PROSPERA.png",
  "50-RMG.png",
  "50-scotiabank.png",
  "50-TD_Canada_Trust.png",
  "50-rfa.png",
  "50-Equitable-Bank-logo.png",
  "50-ap-capital.png",
  "50-antrim.png"
]

const bankLogoAlt = {
  "50-WEALTHONEBANK.png": "Wealth One Bank of Canada",
  "50-b2b.png": "B2B Bank",
  "50-blueshore.png": "BlueShore Financial",
  "50-bridgewater.jpg": "Bridgewater Bank",
  "50-costcapital.png": "Coast Capital Savings",
  "50-envision.png": "Envision Financial",
  "50-FIRSTNATIONAL.png": "First National Financial Corporation",
  "50-homeTrust.png": "Home Trust Company",
  "50-icici.png": "ICICI Bank Canada",
  "50-LENDWISE.png": "Lendwise",
  "50-manulife.png": "Manulife",
  "50-mcap.png": "MCAP Canada",
  "50-MERIX.png": "Merix Financial",
  "50-OPTIMUM.png": "Optimum Mortgage",
  "50-PEOPLESTRUST.png": "Peoples Group",
  "50-PROSPERA.png": "Prospera Credit Union",
  "50-RMG.png": "RMG Mortgages Canada",
  "50-scotiabank.png": "Scotiabank",
  "50-TD_Canada_Trust.png": "TD Canada Trust",
  "50-rfa.png": "RFA Canada",
  "50-Equitable-Bank-logo.png": "Equitable Bank",
  "50-ap-capital.png": "AP Capital",
  "50-antrim.png": "Antrim Investments"
}

const BankSection = (props) => {
  return (
    <div className="lending-partners">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        infinite={true}
        autoPlay={false}
        // autoPlaySpeed={1500}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        arrows={true}
        containerClass="carousel-container"
        partialVisible="false"
        // dotListClass="custom-dot-list-style"
        itemClass="carousel-item"
      >
        {bankLogos.map((logo, id) => (
          <div key={id} className="img-container">
            <Image imgsrc={logo} alt={bankLogoAlt[logo]} />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default BankSection
