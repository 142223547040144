import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import "../../styles/sections/testimonial-section.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 3,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 2,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1
  },
  inBetween: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
    // slidesToSlide: 1, // optional, default to 1.
  }
}

let TestimonialSection = ({ testimonials }) => {
  if (!testimonials) return null
  return (
    <section id="testimonials">
      {/* <h2 className="primary-color uppercase">Happy Borrowers</h2> */}
      <h2>Happy Borrowers</h2>
      {testimonials.testimonial && testimonials.testimonial.length > 0 && (
        <div className="testimonials">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={500}
            keyBoardControl={true}
            transitionDuration={500}
            arrows={true}
            containerClass="carousel-container"
            partialVisible="false"
            // dotListClass="custom-dot-list-style"
            itemClass="carousel-item"
          >
            {testimonials.testimonial.map((testimonial, tId) => (
              <div className="testimonial" key={tId}>
                <div className="testimonail__inner">
                  <p>"{testimonial.text}"</p>
                  <div className="testimonial-name">
                    <h4>- {testimonial.name}</h4>
                    <div className="star">
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                      <FontAwesomeIcon icon={faStar} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </section>
  )
}

export default TestimonialSection
