import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import PageTransitionContainer from "../components/PageTransitionContainer"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import HomeSection from "../components/Home/HomeSection"
import BankSection from "../components/Home/BankSection"
import RateSection from "../components/Home/RateSection"
import TextSectionFirst from "../components/Home/TextSectionFirst"
import TestimonialSection from "../components/Home/TestimonialSection"
import TextSectionSecond from "../components/Home/TextSectionSecond"
import CTABlock1 from "../components/CTABlock1"
import FAQSection from "../components/Home/FAQSection"
// import ProcessSection from "../components/Home/ProcessSection"
// import DiscussForm from "../components/Home/DiscussForm"
import "../styles/pages/home.css"
// import { getCLS, getFCP, getFID, getLCP, getTTFB } from "web-vitals"

const indexPageSchema = {
  "@context": "http://schema.org",
  "@type": "LocalBusiness",
  name: "Ratelock.ca",
  openingHours:
    "Mo 1000-1900 Tu 1000-1900 We 1000-1900 Th 1000-1900 Fr 1000-1900",
  additionalProperty: "https://en.wikipedia.org/wiki/Mortgage_broker",
  additionalType: "http://productontology.org/id/Mortgage_broker",
  areaServed: {
    "@type": "GeoCircle",
    name: "Ratelock.ca service area",
    geoMidpoint: {
      "@type": "GeoCoordinates",
      postalCode: "V5J 3J4",
      latitude: "49.2011847",
      longitude: "-122.9845201",
      description: "Our office location can be found here.",
      name: "Ratelock.ca geo location"
    },
    geoRadius: "20000",
    description:
      "Ratelock.ca proudly serves the entire city of Burnaby British Columbia"
  },
  email: "hello@ratelock.ca",
  address: {
    "@type": "PostalAddress",
    postalCode: "V5J 3J4",
    addressRegion: "British Columbia",
    addressCountry: "Canada",
    streetAddress: "5589 Byrne Road #Suite D - 227",
    addressLocality: "Burnaby"
  },
  paymentAccepted: "Cash, Credit Card",
  telephone: "16045375353",
  image: {
    "@type": "ImageObject",
    name: "Ratelock.ca image",
    url:
      "https://images.prismic.io/ratelockca/5c018307-5495-4b65-8083-c53983fd7601_home-section.png?auto=compress,format"
  },
  ContactPoint: {
    "@type": "ContactPoint",
    name: "Ratelock.ca inquiry",
    availableLanguage:
      "https://en.wikipedia.org/wiki/List_of_dialects_of_the_English_language",
    telephone: "+16045375353",
    contactType: "customer support",
    "@id": ""
  },
  geo: {
    "@type": "GeoCoordinates",
    latitude: "49.2011847",
    longitude: "-122.9845201",
    description: "Our office location can be found here.",
    name: "Ratelock.ca geo location"
  },
  description:
    "Ratelock provides home equity loans to improve your home, start your business, get out of debt faster, or even help with emergency needs. We are proud to be the number one financing solution in Burnaby, BC, with many satisfied clients over the years. Our goal is to provide our clients a bridge that connects them to the best home loan brokers in Canada. With our service, you will experience a safe, reliable, and simple refinancing experience. We take away the hassle of negotiation with the financial institution for the best possible home loan rates from you and provide the lowest rate that you can get. Our mission is to find the perfect mortgage plan that will keep you satisfied and save you thousands of dollars every year.",
  priceRange: "",
  url: "https://ratelock.ca/",
  currenciesAccepted: "",
  hasMap: "https://maps.google.com/?cid=16207445943143180678",
  logo:
    "https://images.prismic.io/ratelockca/db486a8d-74fd-4cc5-9e5a-4763f80499f0_standalone_padded_large.jpg?auto=compress,format",
  serviceArea: {
    "@type": "GeoCircle",
    name: "Ratelock.ca service area",
    geoMidpoint: {
      "@type": "GeoCoordinates",
      postalCode: "V5J 3J4",
      latitude: "49.2011847",
      longitude: "-122.9845201",
      description: "Our office location can be found here.",
      name: "Ratelock.ca geo location"
    },
    geoRadius: "20000",
    description: "Ratelock.ca proudly serves the entire city of Burnaby"
  },
  sameAs: [
    "https://www.facebook.com/ratelock.ca",
    "https://twitter.com/ratelock_ca",
    "https://www.instagram.com/ratelock.ca"
  ],
  "@id": "https://ratelock.ca/"
}

const IndexPageTemplate = ({
  heading,
  headingDescription,
  // subheading,
  // process,
  testimonials,
  faq,
  bottomCTA,
  location
  // discussForm,
  // contactInfo
}) => {
  // getCLS(console.log)
  // getFCP(console.log)
  // getFID(console.log)
  // getLCP(console.log)
  // getTTFB(console.log)
  return (
    <div className="container">
      <Seo title="RateLock.ca" location={location} schema={indexPageSchema} />
      <HomeSection heading={heading} headingDescription={headingDescription} />
      <BankSection />
      <RateSection />
      {/* <ServicesSection heading={heading} subheading={subheading} /> */}
      {/* <ProcessSection process={process} contactInfo={contactInfo} /> */}
      {/* <DiscussForm {...discussForm} /> */}
      <TextSectionFirst />
      <TestimonialSection testimonials={testimonials} />
      <CTABlock1 {...bottomCTA} />
      <TextSectionSecond />
      <FAQSection {...faq} />
      {/* <CTABlock {...bottomCTA} /> */}
    </div>
  )
}

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  process: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    description: PropTypes.string,
    blocks: PropTypes.array
  }),
  testimonials: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    testimonial: PropTypes.array
  }),
  faq: PropTypes.shape({
    cta: PropTypes.string,
    questions: PropTypes.array
  }),
  bottomCTA: PropTypes.shape({
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    ctaType: PropTypes.string,
    ctaText: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaContact: PropTypes.string
  }),
  discussForm: PropTypes.shape({
    heading: PropTypes.string,
    fields: PropTypes.array
  }),
  contactInfo: PropTypes.shape({
    title: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  })
}

const IndexPage = ({ data, location }) => {
  const { index, contactInfo } = data
  const { frontmatter } = index
  return (
    <PageTransitionContainer>
      <Layout page="home" location={location}>
        <IndexPageTemplate
          {...frontmatter}
          contactInfo={contactInfo.frontmatter}
          location={location}
        />
      </Layout>
    </PageTransitionContainer>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        headingDescription
        process {
          title
          description
          blocks {
            name
            text
            buttonText
            type
          }
        }
        testimonials {
          title
          subTitle
          testimonial {
            name
            text
          }
        }
        faq {
          cta
          questions {
            question
            answer
          }
        }
        bottomCTA {
          heading
          subHeading
          ctaType
          ctaLink
          ctaText
          ctaContact
        }
        discussForm {
          heading
          fields {
            type
            name
            placeholder
            options {
              label
              value
              info
            }
          }
        }
      }
    }
    contactInfo: markdownRemark(
      frontmatter: { templateKey: { eq: "contact-page" } }
    ) {
      frontmatter {
        title
        phone
        email
        addressLine1
        addressLine2
      }
    }
  }
`
