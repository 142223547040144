import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import {
  faDollarSign,
  faUser,
  faList,
  faLock
} from "@fortawesome/free-solid-svg-icons"
import {
  faCheckCircle,
  faCheckSquare
} from "@fortawesome/free-regular-svg-icons"
import "../../styles/sections/text-section-second.css"

const features = [
  {
    heading: "Simple",
    icon: faCheckCircle,
    text: "When we claim, ‘just like that,’ our experts guarantee it— access the very best plus most current home loan rates within a matter of seconds."
  },
  {
    heading: "Free",
    icon: faDollarSign,
    text: "That’s right— our service is total, 100% complimentary for our customers. Comparing home loan prices won’t cost you a penny."
  },
  {
    heading: "Uncomplicated",
    icon: faCheckSquare,
    text: "1. Enter your postal code for the Canadian province or Canadian territory you live in to find competitive home mortgage rates 2. Select the very best rate. It is really that simple."
  },
  {
    heading: "Unbiased",
    icon: faUser,
    text: "We’re impartial. At Ratelock.ca, we allow financial institutions and brokers to provide their best home loan and mortgage offers to you on an unbiased network."
  },
  {
    heading: "Detailed",
    icon: faList,
    text: "We make ‘shopping around’ to a brand-new degree. Find the lowest rates on home loan and mortgage interest rates Canada wide from over 40 financial institutions and brokers."
  },
  {
    heading: "Safety",
    icon: faLock,
    text: "Feel confident, mortgage buyers. Your information is privacy protected. We’ll never share your private information with any 3rd party at any time."
  }
]

const Feature = ({ heading, text, icon }) => {
  return (
    <div className="feature">
      <FontAwesomeIcon icon={icon} />
      <div>
        <h4>{heading}</h4>
        <p>{text}</p>
      </div>
    </div>
  )
}

const TextSectionSecond = (props) => {
  return (
    <section className="breakout text-block">
      <div className="container text-block-2">
        <div className="text-container">
          <h2>Not all bank rates are advertised</h2>
          <p>
            Lock your home loan rates from Banks as well as Brokers throughout
            BC and Canada. Property owners, we have mortgage rates we think
            you’ll find beneficial. And unlike the majority of news on rates, go
            ahead and share this set of interest rates with every one of your
            family and friends. They will thank you.
          </p>
          <p>
            Financial institutions seldom provide their most affordable home
            loan rates upfront. This little-known reality typically forces
            Canadians to bargain discounts over the phone or in person.
          </p>
          <p>You don’t need the hassle.</p>
          <p>
            <a href="/">RateLock.ca</a> tracks the most recent mortgage prices
            in Canada and can help you secure a competitive interest rate. The
            lowest rate for you.
          </p>
          <p>
            <strong>Again - Not all bank rates are advertised.</strong>
          </p>
          <p>
            Our job is to hunt for the lowest rates for you. Home mortgage rate
            comparison is vital if you intend to get an affordable home loan
            rate in Canada. By reviewing mortgage prices on Ratelock.ca, you can
            avoid the back-and-forth with your financial institution and get the
            absolute best rates obtainable in your location as soon as possible.
          </p>
          <p>
            Our users conserve hundreds of thousands of dollars a year on their
            home mortgage rates, and we would like you to join them.
          </p>
          <p>
            <i>
              Continue checking out to learn just how to obtain the best
              mortgage price in Canada.
            </i>
          </p>
        </div>

        <div className="features">
          {features.map((feature, i) => (
            <Feature key={i} {...feature} />
          ))}
        </div>
        <div className="text-cta">
          Get the most accurate rates analyzed from over 40 lenders
          <div>
            <Link to="/apply-now" className="btn">
              Apply Today
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextSectionSecond
