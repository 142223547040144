import React from "react"
import Image from "../Image"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import "../../styles/sections/faq-section.css"
const ReactMarkdown = require("react-markdown")

const FAQSection = ({ cta, questions }) => {
  return (
    <section className="faq-section">
      <div className="text-container">
        <h2>Frequently Asked Questions</h2>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {questions &&
            questions.length > 0 &&
            questions.map((q, id) => (
              <AccordionItem key={id}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <p className="question-faq">{q.question}</p>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ReactMarkdown>{q.answer}</ReactMarkdown>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
        </Accordion>
      </div>
      <div className="image-container">
        <Image imgsrc="faq.png" alt="FAQ" />
      </div>
      <div>
        {cta && (
          <p>
            {cta} Checkout our <a href="/faq">faq page</a> or Call us at{" "}
            <a href="tel:18888779851">1-888-877-9851</a>
          </p>
        )}
      </div>
    </section>
  )
}

export default FAQSection
