import React from "react"
import { Link } from "gatsby"
import "../styles/cta1.css"

let CTABlock = ({
  // ctaContact,
  ctaType,
  ctaLink,
  ctaText
  // heading,
  // subHeading
}) => {
  return (
    <section className="cta-1">
      {/* {heading && <h2>{heading}</h2>}
      {subHeading && <h4 className="subheading">{subHeading}</h4>}
      {ctaType === "call" && (
        <a href={`tel:${ctaContact}`} className="btn">
          {" "}
          {ctaText ? ctaText : "Call"}{" "}
        </a>
      )} */}
      {ctaType === "apply-now" && (
        <Link to={ctaLink} className="btn">
          {ctaText ? ctaText : "Call"}
        </Link>
      )}
    </section>
  )
}

export default CTABlock
