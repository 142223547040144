import React from "react"
import Image from "../Image"
import "../../styles/sections/text-section-first.css"

const TextSectionFirst = (props) => {
  return (
    <section className="breakout text-block">
      <div className="container text-block-1">
        <div className="text-container">
          <h2>
            Match best lending rates and streamline your borrowing experience
          </h2>
          <p>
            With us at <a href="/">RateLock.ca</a>, you’ll have the ability to
            get the best home mortgage rates from almost all home mortgage loan
            providers in Canada. Wish to know what the present mortgage interest
            rates are in Canada today? RateLock.ca collects home mortgage
            prices— all day, every day. Next, we connect you to home loan
            brokers who obtain rates from top lenders.
          </p>
          <p>
            Please complete the questionnaire above to try our totally free,
            no-obligation solution, and off you go to saving, conserving huge
            amounts on your residential mortgage loans.
          </p>
          <p>
            As a matter of fact, RateLock.ca mortgage prices are around two
            entire basis points lower than the bank’s best rates. Clients that
            utilize our service have saved hundreds of thousands of dollars
            every year on their home loan repayments.
          </p>
          <p>
            With numbers like that, it’s not surprising that Canadians are
            increasingly utilizing comparison websites to find the nation’s e
            most affordable mortgage interest rate. We analyze your financial
            situation and find the best rates and the best terms that suit you.
          </p>
          <p>
            Our home loan rate checker is{" "}
            <span className="highlight">Canada-wide</span> and gives quotes from
            over 40 banks and brokers. Whether you live in Vancouver, British
            Columbia, Toronto, Ontario, Montreal, Quebec, Calgary, Alberta or
            anywhere in the vast and beautiful Canada, our home loan interest
            rates are specially designed to fit your need.
          </p>
        </div>
        <div className="image-container">
          <Image imgsrc="work-with-the-best.png" alt="Work With The Best" />
        </div>
      </div>
    </section>
  )
}

export default TextSectionFirst
