import React from "react"
import "../../styles/sections/home-section.css"
import Image from "../Image"
import { navigate } from "gatsby"

const HomeSection = (props) => {
  const { headingDescription } = props
  // Order
  return (
    <section id="home-section">
      <div id="home-inner">
        <div className="left">
          <h1>
            Need a <span style={{ color: "var(--primary)" }}>Mortgage</span>{" "}
            that fits your lifestyle and budget?
          </h1>
          <p>{headingDescription}</p>
        </div>
        <div className="image-container">
          <Image imgsrc="home-section.png" alt="Ratelock.ca | Home" />
        </div>
      </div>

      <div className="main-cta">
        <div className="inner-container">
          <p>
            What type of mortgage are you looking for? Click below to apply now.
          </p>
          <div className="button-block">
            <button className="btn" onClick={() => navigate("/buy-a-home")}>
              Buying A Home
            </button>
            {/* <button className="btn" onClick={() => navigate("/renew")}>
              Renew Mortgage
            </button> */}
            <button className="btn" onClick={() => navigate("/refinance")}>
              Refinance Mortgage
            </button>
          </div>
          <p>Get the most accurate rates analyzed from over 40 lenders</p>
        </div>
      </div>
    </section>
  )
}

export default HomeSection
