import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "../../styles/sections/rate-section.css"
import { navigate } from "gatsby"

const RateSection = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query RateQuery {
          allPrismicRates {
            edges {
              node {
                data {
                  variable_rate
                  fixed_rate
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const prismicData = data.allPrismicRates?.edges?.[0]?.node?.data
        const variableRate = prismicData?.variable_rate
        const fixedRate = prismicData?.fixed_rate
        return (
          <section id="rate-section">
            <div className="breakout">
              <div className="container">
                <h2>Take advantage of today's revised rates</h2>
                <div className="rate-block-container">
                  <div className="rate-block variable-rates">
                    <div className="bg-heading">Lowest Variable Rates</div>
                    <div className="center-flex">
                      <div>
                        <span className="helper-span">as low as</span>
                        <div className="rate">{variableRate}%</div>
                      </div>
                    </div>
                  </div>
                  <div className="rate-block fixed-rate">
                    <div className="bg-heading">Lowest Fixed Rates</div>
                    <div className="center-flex">
                      <div>
                        <span className="helper-span">as low as</span>
                        <div className="rate">{fixedRate}%</div>
                      </div>
                    </div>
                  </div>
                  <div className="button-container">
                    <button
                      className="btn"
                      onClick={() => navigate("/apply-now")}
                    >
                      APPLY NOW
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}

export default RateSection
